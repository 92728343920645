<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/at002.webp">
    <h2 class="name_h2">デューラー A/T002</h2>

    <p class="access_p">
    デューラー A/T002は、2024年2月発売のNEWタイヤです。<br>
    白い文字が特徴的なSUV用オールステージタイヤで、
    白い文字はホワイトゴムを使用したアウトラインホワイトレターになります。<br><br>
    ※サイズによりブラックレターのみの場合があります。<br><br>
    ゴツゴツしたタイヤはクルマの印象を変えたい方におすすめです。<br>
    オールテレーンは凍結路面以外、様々な環境の道に対応しています。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">SUV</p>

    <div class="charge2">
    <div class="charge_table">
      <h2 class="charge_table_h2"><span class="charge_table_span">サイズ表・メーカー希望小売価格</span><br>※4本購入で値引きできます</h2>
      <table class="biz-hour">
        <tbody>
          <tr>
            <th>サイズ</th>
            <th>デザイン</th>
            <th>税込<span class="price_span"> 1本</span></th>
          </tr>
          <tr>
            <td>285/60R18 116T</td>
            <td>BLACK</td>
            <td class="reds"><span class="red_p">¥</span>58,960</td>
          </tr>
          <tr>
            <td>265/60R18 114S XL</td>
            <td>BLACK</td>
            <td class="reds"><span class="red_p">¥</span>55,110</td>
          </tr>
          <tr>
            <td>265/65R17 112S</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>44,110</td>
          </tr>
          <tr>
            <td>265/70R17 115S</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>38,060</td>
          </tr>
          <tr>
            <td>275/70R16 114S</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>38,060</td>
          </tr>
          <tr>
            <td>265/70R16 112S</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>36,630</td>
          </tr>
          <tr>
            <td>245/70R16 111S XL</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>34,980</td>
          </tr>
          <tr>
            <td>215/70R16 100S</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>28,600</td>
          </tr>
          <tr>
            <td>175/80R16 91S</td>
            <td>BLACK</td>
            <td class="reds"><span class="red_p">¥</span>22,880</td>
          </tr>
          <tr>
            <td>265/70R15 112T</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>35,310</td>
          </tr>
          <tr>
            <td>205/70R15 96S</td>
            <td>WHITE</td>
            <td class="reds"><span class="red_p">¥</span>24,860</td>
          </tr>
          <tr>
            <td>175/80R15 90S</td>
            <td>BLACK</td>
            <td class="reds"><span class="red_p">¥</span>20,570</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <CarouselsSuv/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsSuv from '@/components/CarouselsSuv.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsSuv,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}

.charge2{
  padding-bottom: 3rem;
  padding-top: 0rem;
}
@media screen and (min-width: 520px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
@media screen and (min-width: 1280px) {
  .charge_table table{
    margin: 0 auto;
    width: 70%;
  }
  .charge_table ul{
    text-align: center;
    display: inline-block;
  }
  ul{
    display: inline-block;
    text-align: center;
  }
  .price_class1{
    display: inline-block;
    text-align: center;
  }
  .price_class2{
    text-align: center;
    display: inline-block;
    padding-right: 10%;
  }
  .price_class3{
    text-align: center;
    display: inline-block;
  }
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}

.biz-hour th, .biz-hour td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  /* ２番目以降の線 */
  /* 色はお好みで */
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* 上段の背景色 */
.biz-hour th {
  /* background: black; */
  font-weight: bold;
}

/* 土曜日の文字色 */
.biz-hour th.sat {
  color: #3db2da;
}

/* 日曜日の文字色 */
.biz-hour th.sun {
  color: #e66a6a;
}
</style>
